<template>
  <layout>
    <v-card>
        <v-card-title>
            <v-icon
                large
                left
            >
                mdi-road
            </v-icon>
            <span class="title font-weight-light">นำข้อมูลงบประมาณเข้าระบบ</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3">
        <v-card-title>
            <span class="subtitle-1">รายละเอียด</span>
        </v-card-title>
        <v-divider class="mx-4 mb-5" />
        <ValidationObserver ref="form">
            <v-form
                ref="form"
                lazy-validation
            >
                <v-container class="body-2">
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ปีงบประมาณ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="fiscalyear"
                                rules="required"
                            >
                              <v-select
                                  :items="fiscalyearItems"
                                  v-model="fiscalyear"
                                  :error-messages="errors"
                                  dense
                                  outlined
                              ></v-select>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ไฟล์ Excel งบประมาณ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="fileExcel"
                                rules="required"
                            >
                            <v-file-input
                              v-model="fileExcel"
                              accept=".xlsx, .xls"
                              :error-messages="errors"
                              outlined
                              dense
                            ></v-file-input>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-divider class="mx-4 mb-5" />
                    <v-row>
                        <v-col>
                          <v-btn depressed color="primary" @click="display()" class="mr-10">
                                ตรวจสอบและแสดงข้อมูล
                            </v-btn>
                            <v-btn depressed color="success" @click="save()">
                                นำข้อมูลเข้าระบบ
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-divider class="my-6" />
                    <v-row>
                      <v-col>
                          <v-card outlined>
                            <v-data-table
                                :headers="headers"  
                                :items="budgetData"
                                disable-sort
                                hide-default-footer
                                disable-pagination
                            >
                            </v-data-table>
                          </v-card>
                      </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </ValidationObserver>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";
import { read, utils } from 'xlsx';

export default {
    data: () => ({
        storeUser: null,
        uploadDate: new Date().toISOString().substr(0, 10),
        formChkErrors: false,
        fiscalyear: (new Date().getFullYear() + 1),
        fiscalyearItems: [],
        fileExcel: [],
        budgetData: [],
        headers: [
            // {
            //     text: "fund",
            //     value: "fund",
            // },
            // {
            //     text: "fund text",
            //     value: "fundtext"
            // },
            {
                text: "fund center",
                value: "fundcenter"
            },
            {
                text: "fund center text",
                value: "fundcentertext"
            },
            {
                text: "FA",
                value: "fa"
            },
            {
                text: "FA text",
                value: "fatext"
            },
            {
                text: "FP",
                value: "fp"
            },
            {
                text: "FP Text",
                value: "fptext"
            },
            // {
            //     text: "CI",
            //     value: "ci"
            // },
            // {
            //     text: "CI Text",
            //     value: "citext"
            // },
            {
                text: "budget",
                value: "budget"
            }
        ]
    }),
    created() {
        this.storeUser = this.$store.getters.user
    },
    async mounted() {
      this.$store.commit('setLoading', true)
      var startYear = new Date().getFullYear() + 5
      var endYear = 2000
      for (let index = startYear; index >= endYear; index--) {
        this.fiscalyearItems.push(index)
      }
      this.$store.commit('setLoading', false)
    },
    methods: {
      async display() {
        this.$store.commit('setLoading', true)
        // console.log(this.fileExcel)
        this.formChkErrors = false
        this.formChkErrors = await this.$refs.form.validate()

        if(this.formChkErrors){
            // const f = await (this.fileExcel).arrayBuffer();
            // const wb = read(f)
            // const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
            // // console.log(data)
						// var out = data.map(function(item) {
						// 	item.fatext = item.fatext.trim();
						// 	item.fptext = item.fptext.trim();
						// 	return item;
						// });
            this.budgetData = await this.readExcelFile()
						console.log(this.budgetData)
            this.$store.commit('setLoading', false)
        }else{
            this.$store.commit('setLoading', false)
        }
      },
			async readExcelFile() {
				const f = await (this.fileExcel).arrayBuffer();
				const wb = read(f)
				const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { defval: ""});
				// console.log(data)
				var out = data.map(function(item) {
					item.fatext = item.fatext.trim();
					item.fptext = item.fptext.trim();
					if(item.budget == "" || item.budget == 0){
						item.budget = 0
					}
					return item;
				});

				return out
			},
      async save() {
          this.$store.commit('setLoading', true)
        
          this.formChkErrors = false
          this.formChkErrors = await this.$refs.form.validate()

					if(this.formChkErrors){
						if(this.budgetData == null || this.budgetData.length <= 0) {
							// const f = await (this.fileExcel).arrayBuffer();
							// const wb = read(f)
							// const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
							this.budgetData = await this.readExcelFile()
						}

						const req = {
							fiscal_year: this.fiscalyear,
							budgetData: this.budgetData
						}
						let res = (await Api().post("/upload/budget", req)).data;

						if (res.status == 'success') {
								this.$store.commit('setLoading', false)
								await this.$refs.confirm.open('ข้อความ', 'บันทึกข้อมูลเรียบร้อย', { color: 'primary', noconfirm: true })
								// this.$router.push('/staff')
						}else{
								this.$store.commit('setLoading', false)
						}
						
					}else{
						this.$store.commit('setLoading', false)
					}
      }
    }
};
</script>